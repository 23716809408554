import Alpine from "alpinejs";

// core version + navigation, pagination modules:
import Swiper, {
  Navigation,
  EffectFade,
  Autoplay,
} from "swiper";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

// init Swiper:
Swiper.use([Navigation, EffectFade, Autoplay]);
window.Swiper = Swiper;

window.Alpine = Alpine;

Alpine.start();